import { Box, Checkbox, Radio, Text } from "@mantine/core";
import {
  AssignmentQuestion,
  Question,
  QuestionType,
} from "modules/questions/question.types";
import MarkdownDisplay from "~/components/Markdown/MarkdownDisplay";

type QuestionContentProps = {
  question: AssignmentQuestion["question"] | Question;
};

type QuestionContentAreaProps = {
  question: AssignmentQuestion["question"] | Question;
  hideLabel?: boolean;
};

export function ShortAnswerContent({
  question,
  hideLabel,
}: QuestionContentAreaProps) {
  if (
    question.questionContent.type !== QuestionType.SHORT_ANSWER &&
    question.questionContent.type !== QuestionType.CLOZE
  ) {
    return null;
  }

  return (
    <Box>
      {!hideLabel && (
        <Text className="text-gray-700 font-medium text-sm mb-2">
          Acceptable Answers
        </Text>
      )}
      <Box className="space-y-2 pl-2">
        {question.questionContent.acceptableAnswers.map((answer, index) => (
          <Box key={index} className="text-gray-900">
            • {answer}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export function MultipleChoiceContent({
  question,
  hideLabel,
}: QuestionContentAreaProps) {
  if (
    question.questionContent.type !== QuestionType.MULTIPLE_CHOICE &&
    question.questionContent.type !== QuestionType.TRUE_FALSE
  ) {
    return null;
  }

  return (
    <Box>
      {!hideLabel && (
        <Text className="text-gray-700 font-medium text-sm mb-2">
          Answer Choices
        </Text>
      )}
      <Box className="space-y-2 pl-2">
        {question.questionContent.answerChoices.map((choice) => (
          <Box key={choice.id} className="flex items-center p-2">
            <Radio
              checked={choice.isCorrect}
              className="mr-3 pointer-events-none"
              readOnly
            />
            <Text className="text-gray-900">{choice.answerText}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export function SelectAllContent({
  question,
  hideLabel,
}: QuestionContentAreaProps) {
  if (question.questionContent.type !== QuestionType.SELECT_ALL) {
    return null;
  }

  return (
    <Box>
      {!hideLabel && (
        <Text className="text-gray-700 font-medium text-sm mb-2">
          Answer Choices
        </Text>
      )}
      <Box className="space-y-2 pl-2">
        {question.questionContent.answerChoices.map((choice) => (
          <Box key={choice.id} className="flex items-center p-2">
            <Checkbox
              checked={choice.isCorrect}
              className="mr-3 pointer-events-none"
              readOnly
            />
            <Text className="text-gray-900">{choice.answerText}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export function QuestionContentArea({
  question,
  hideLabel,
}: QuestionContentAreaProps) {
  switch (question.questionContent.type) {
    case QuestionType.SHORT_ANSWER:
      return <ShortAnswerContent question={question} hideLabel={hideLabel} />;
    case QuestionType.MULTIPLE_CHOICE:
      return (
        <MultipleChoiceContent question={question} hideLabel={hideLabel} />
      );
    case QuestionType.SELECT_ALL:
      return <SelectAllContent question={question} hideLabel={hideLabel} />;
    case QuestionType.TRUE_FALSE:
      return (
        <MultipleChoiceContent question={question} hideLabel={hideLabel} />
      );
    case QuestionType.CLOZE:
      return <ShortAnswerContent question={question} hideLabel={hideLabel} />;
  }
}

export function QuestionContent({ question }: QuestionContentProps) {
  return (
    <Box className="space-y-6 mb-8">
      <Box>
        <Text className="text-gray-700 font-medium text-sm mb-2">Question</Text>
        <Box className="pl-2">
          <MarkdownDisplay
            content={question.questionContent.questionText}
            disableTextToSpeech
          />
        </Box>
      </Box>
      <QuestionContentArea question={question} />
      {question.questionContent.explanation && (
        <Box>
          <Text className="text-gray-700 font-medium text-sm mb-2">
            Explanation
          </Text>
          <Box className="pl-2 text-gray-600">
            <MarkdownDisplay
              content={question.questionContent.explanation}
              disableTextToSpeech
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
